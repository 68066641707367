import React from 'react';
import Image from 'reusecore/src/elements/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import DocumentationSection from '../../containers/SaasModern/Documentation';
import '../../containers/SaasModern/Documentation/documentation.style';
import example_carousel from '../../../../common/src/assets/image/2019/example_carousel.png';
import search_for_template_week_column from '../../../../common/src/assets/image/2019/search_for_template_week_column.png';
import data_source_appearance from '../../../../common/src/assets/image/2019/search_for_template_week_column.png';
import powerpoint_create_calendar_with_carousel from '../../../../common/src/assets/image/2019/powerpoint_create_calendar_with_carousel.png';
import powerpoint_example_output from '../../../../common/src/assets/image/2019/powerpoint_example_output.png';
import template_year_photo from '../../../../common/src/assets/image/2019/template_year_photo.png';
import template_day_with_week_grid from '../../../../common/src/assets/image/2019/template_day_with_week_grid.png';
import simple_agenda from '../../../../common/src/assets/image/2019/simple_agenda.png';
import week_columns_2_weeks from '../../../../common/src/assets/image/2019/week_columns_2_weeks.png';
import day_with_timeslot_columns from '../../../../common/src/assets/image/2019/day_with_timeslot_columns.png';
import month_2_pages from '../../../../common/src/assets/image/2019/month_2_pages.png';
import week_grid_2_pages from '../../../../common/src/assets/image/2019/week_grid_2_pages.png';
import list_with_meeting_attendees from '../../../../common/src/assets/image/2019/list_with_meeting_attendees.png';
import month_span_pages from '../../../../common/src/assets/image/2019/month_span_pages.png';
import six_month_ledger from '../../../../common/src/assets/image/2019/six_month_ledger.png';
import we_can_create_custom_templates from '../../../../common/src/assets/image/2019/we_can_create_custom_templates.png';
import item_font_size from '../../../../common/src/assets/image/2019/item_font_size.png';
import item_font_size_example from '../../../../common/src/assets/image/2019/item_font_size_example.png';
import use_bold_text from '../../../../common/src/assets/image/2019/use_bold_text.png';
import use_bold_text_example from '../../../../common/src/assets/image/2019/use_bold_text_example.png';
import all_day_area_text_behavior from '../../../../common/src/assets/image/2019/all_day_area_text_behavior.png';
import all_day_area_text_behavior_example from '../../../../common/src/assets/image/2019/all_day_area_text_behavior_example.png';
import show_times_multiday_items_in_all_day_area from '../../../../common/src/assets/image/2019/show_times_multiday_items_in_all_day_area.png';
import show_times_multiday_items_in_all_day_area_example from '../../../../common/src/assets/image/2019/show_times_multiday_items_in_all_day_area_example.png';
import show_tasks_that_have_no_due_date from '../../../../common/src/assets/image/2019/show_tasks_that_have_no_due_date.png';
import show_blank_line_between_items from '../../../../common/src/assets/image/2019/show_blank_line_between_items.png';
import show_blank_line_between_items_example from '../../../../common/src/assets/image/2019/show_blank_line_between_items_example.png';
import numeric_time_format from '../../../../common/src/assets/image/2019/numeric_time_format.png';
import item_sort_order from '../../../../common/src/assets/image/2019/item_sort_order.png';
import item_sort_order_calendars from '../../../../common/src/assets/image/2019/item_sort_order_calendars.png';
import item_sort_order_example from '../../../../common/src/assets/image/2019/item_sort_order_example.png';
import show_time_and_locations_on_separate_lines from '../../../../common/src/assets/image/2019/show_time_and_locations_on_separate_lines.png';
import show_time_and_locations_on_separate_lines_example from '../../../../common/src/assets/image/2019/show_time_and_locations_on_separate_lines_example.png';
import legend_example from '../../../../common/src/assets/image/2019/legend_example.png';
import legend_customize from '../../../../common/src/assets/image/2019/legend_customize.png';
import insert_mini_calendar_before from '../../../../common/src/assets/image/2019/insert_mini_calendar_before.png';
import insert_mini_calendar_after from '../../../../common/src/assets/image/2019/insert_mini_calendar_after.png';
import mini_calendar_example_template from '../../../../common/src/assets/image/2019/mini_calendar_example_template.png';
import mini_calendar_customize_template from '../../../../common/src/assets/image/2019/mini_calendar_customize_template.png';
import categories_import from '../../../../common/src/assets/image/2019/categories_import.png';
import categories_defined from '../../../../common/src/assets/image/2019/categories_defined.png';
import categories_legend_in_template from '../../../../common/src/assets/image/2019/categories_legend_in_template.png';
import categories_inserted from '../../../../common/src/assets/image/2019/categories_inserted.png';
import category_vueminder_options from '../../../../common/src/assets/image/2019/category_vueminder_options.png';
import filter_none from '../../../../common/src/assets/image/2019/filter_none.png';
import filter_applied from '../../../../common/src/assets/image/2019/filter_applied.png';
import calendar_preset_save from '../../../../common/src/assets/image/2019/calendar_preset_save.png';
import calendar_preset_select from '../../../../common/src/assets/image/2019/calendar_preset_select.png';
import item_template_default from '../../../../common/src/assets/image/2019/item_template_default.png';
import item_template_custom from '../../../../common/src/assets/image/2019/item_template_custom.png';
import word_formats from '../../../../common/src/assets/image/2019/word_formats.png';
import word_html_format_example from '../../../../common/src/assets/image/2019/word_html_format_example.png';
import add_todoist_menu from '../../../../common/src/assets/image/2019/add_todoist_menu.png';
import add_csv_and_excel_menu from '../../../../common/src/assets/image/2019/add_csv_and_excel_menu.png';
import add_todoist_instructions from '../../../../common/src/assets/image/2019/add_todoist_instructions.png';
import add_todoist_calendars from '../../../../common/src/assets/image/2019/add_todoist_calendars.png';
import add_csv_mapping from '../../../../common/src/assets/image/2019/add_csv_mapping.png';
import add_google_account from '../../../../common/src/assets/image/2019/add_google_account.png';
import google_allow_access from '../../../../common/src/assets/image/2019/google_allow_access.png';
import add_google_calendars from '../../../../common/src/assets/image/2019/add_google_calendars.png';
import add_vueminder from '../../../../common/src/assets/image/2019/add_vueminder.png';

const offset = 120;

const toc = (
  <div>
    <p>
      We've added and improved many features, mostly based on customer feedback.
      We're very excited to release this update, which is our biggest to date.
      Here's a quick summary of what's new...
    </p>
    <ul>
      <li>
        <AnchorLink href="#new-user-interface" offset={offset}>
          New user interface
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#powerpoint" offset={offset}>
          Generate calendars in PowerPoint
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#new-templates" offset={offset}>
          Generate calendars using new templates
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#more-template-settings" offset={offset}>
          Customize the output with more template settings
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#include-legend-in-templates" offset={offset}>
          Include legend in templates
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#insert-mini-calendar" offset={offset}>
          Insert mini calendar
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#categories" offset={offset}>
          Import, create, and color-code categories
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#filters" offset={offset}>
          Filter by category or item type
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#presets" offset={offset}>
          Define calendar data source presets
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#item-template" offset={offset}>
          Customize item templates
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#word-formats" offset={offset}>
          Insert calendar using additional formats in Word
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#more-calendar-data-source-types" offset={offset}>
          Connect to more calendar data source types
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#google-accounts" offset={offset}>
          Connect to any number of Google accounts
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#vueminder" offset={offset}>
          Specify VueMinder data file location
        </AnchorLink>
      </li>
      <li>
        <AnchorLink href="#many-other-improvements" offset={offset}>
          Many other improvements and corrections
        </AnchorLink>
      </li>
    </ul>
  </div>
);

const newUserInterface = (
  <div id="new-user-interface">
    <h2>New user interface</h2>
    <p>
      The user interface in PrintableCal 2019 has been completely rewritten. If
      you've used PrintableCal in the past, most of the user interface will
      still be familiar, but everything will look better now - especially when
      displayed on a 4K monitor. We've made the user interface more intuitive,
      but we haven't removed any functionality. In fact, we've added a lot of
      new capabilities.
    </p>
    <p>
      An example carousel is now provided, so you can see a sample of each
      template before making a selection and advancing to the next step. The
      carousel window can be moved by dragging it with the mouse. If you don't
      want to see the carousel window, it can be closed by clicking the X button
      or unchecking the "Show example carousel" box.
    </p>
    <p>
      <Image lazy={false} alt="" src={example_carousel} />
    </p>
    <p>
      The template list has also been improved to be searchable. When a word or
      phrase is entered in the search box, the list will automatically update to
      show only the matching templates.
    </p>
    <p>
      <Image
        alt=""
        src={search_for_template_week_column}
        style={{ width: 447 }}
      />
    </p>
    <p>
      In the previous version, the check boxes for selecting calendar data
      sources were very small when running on a 4K display. Also, it wasn't very
      obvious the colors and fonts could be adjusted for each calendar data
      source, since the panel to modify those settings would only appear after
      selecting a calendar, and the panel was shown at the bottom of the list
      where it was easy to miss.
    </p>
    <p>
      PrintableCal 2019 fixes these issues. Now, the check boxes are always the
      correct size, regardless of your display. The check boxes will also be
      color-coded, and when you add or select a calendar you'll see its settings
      right below its name. Instead of 3 color boxes shown side-by-side with no
      labels, the new version will show labels for each color box. A border
      thickness can also be specified, which wasn't possible in the previous
      version.
    </p>
    <p>
      You might also notice some other things have been added to the user
      interface, such as the{' '}
      <AnchorLink href="#presets" offset={offset}>
        ability select a preset
      </AnchorLink>{' '}
      and{' '}
      <AnchorLink href="#filters" offset={offset}>
        apply additional filtering criteria
      </AnchorLink>{' '}
      to the output.
    </p>
    <p>
      <Image lazy={false} alt="" src={data_source_appearance} style={{ width: 475 }} />
    </p>
  </div>
);

const generateCalendarsInPowerPoint = (
  <div id="powerpoint">
    <h2>Generate calendars in PowerPoint</h2>
    <p>
      PrintableCal 2019 now includes an add-in for PowerPoint. When generating a
      calendar in PowerPoint, you can choose to insert into the current slide or
      a new slide.
    </p>
    <p>
      <Image lazy={false} alt="" src={powerpoint_create_calendar_with_carousel} />
    </p>
    <p>
      The calendar will insert as a picture and can be moved or resized on the
      slide however you'd like. If the output spans multiple pages, each page
      will be added as a slide. For example, when generating 3 months using the
      default month template, 3 slides will be added (or 2 slides if you choose
      to add the first month to the current slide).
    </p>
    <p>
      <Image lazy={false} alt="" src={powerpoint_example_output} />
    </p>
  </div>
);

const generateCalendarsUsingNewTemplates = (
  <div id="new-templates">
    <h2>Generate calendars using new templates</h2>
    <p>PrintableCal 2019 includes 10 new customizable templates.</p>
    <h3>Year - Photo Calendar</h3>
    <p>
      Use this template to create a photo calendar for a whole year. A different
      photo can be selected for each month. Templates are provided for combining
      photos and months on single pages or separate pages (like a traditional
      wall calendar).
    </p>
    <p>
      <Image lazy={false} alt="" src={template_year_photo} />
    </p>
    <h3>Day - 1 Day/Page with Week Grid</h3>
    <p>
      Generate a calendar showing 1 day per page with a week grid on the right
      side of the page. Events will be placed within their corresponding time
      slots. Overlapping events will be shown side-by-side.
    </p>
    <p>
      <Image lazy={false} alt="" src={template_day_with_week_grid} style={{ width: 800 }} />
    </p>
    <h3>Agenda - Simple Agenda Template</h3>
    <p>
      Generate using a simplified version of the Agenda template which shows
      items in a single column with minimal formatting.
    </p>
    <p>
      <Image lazy={false} alt="" src={simple_agenda} style={{ width: 800 }} />
    </p>
    <h3>Week - Columns, 2 Weeks/Page</h3>
    <p>
      Generate 2 weeks to a single page, with one week on the top half of the
      page and one on the bottom half. Days will be shown as columns containing
      time slots.
    </p>
    <p>
      <Image lazy={false} alt="" src={week_columns_2_weeks} />
      <br />
      <br />
    </p>
    <h3>Day - 1 Day/Page with Timeslot Columns</h3>
    <p>
      Generate a calendar showing 1 day per page. Individual calendar data
      sources will be shown as rows and time slots will be shown as columns.
    </p>
    <p>
      <Image lazy={false} alt="" src={day_with_timeslot_columns} />
    </p>
    <h3>Month Template - Two Pages</h3>
    <p>Generate a single month split over two pages.</p>
    <p>
      <Image lazy={false} alt="" src={month_2_pages} />
    </p>
    <h3>Week Grid Template - 2 Pages</h3>
    <p>
      Generate a calendar showing 7 days split between 2 pages in portrait mode,
      with additional space provided for the 7th day.
    </p>
    <p>
      <Image lazy={false} alt="" src={week_grid_2_pages} />
    </p>
    <h3>List Template with Meeting Attendees</h3>
    <p>
      Generate a basic list of events or tasks for a specified date range,
      optionally including events or tasks that have no associated date. Meeting
      attendees will also be shown where applicable.
    </p>
    <p>
      <Image lazy={false} alt="" src={list_with_meeting_attendees} />
    </p>
    <h3>Month - Span Multiple Pages</h3>
    <p>
      Generate a month that can span multiple pages, depending on the page size
      and amount of space required. Page breaks will be inserted before or after
      week rows, not in the middle of a week.
    </p>
    <p>
      <Image lazy={false} alt="" src={month_span_pages} style={{ width: 800 }} />
    </p>
    <h3>6-Month Template (Ledger 11x17 Page Size)</h3>
    <p>
      Generate 6 months to a single 11x17 sized page. Dates can be color-coded
      based on the first event or task. Events and tasks will also be listed to
      the right of each month.
    </p>
    <p>
      <Image lazy={false} alt="" src={six_month_ledger} style={{ width: 800 }} />
    </p>
    <h3>Get Your Own Custom Template</h3>
    <p>
      We've also added a little self-promotional plug at the bottom of the
      template list to let you know that we can create custom templates if none
      of the provided templates meet your needs, or if you don't want to mess
      with trying to customize a template yourself. This message can be
      minimized if you don't want to see it, and it will stay minimized.
    </p>
    <p>
      <Image
        alt=""
        src={we_can_create_custom_templates}
        style={{ width: 471 }}
      />
    </p>
  </div>
);

const customizeOutputWithMoreSettings = (
  <div id="more-template-settings">
    <h2>Customize the output with more template settings</h2>
    <p>
      We haven't just added more templates to PrintableCal 2019. We've also
      added more settings for all the existing templates.&nbsp;
    </p>
    <h3>Item font size</h3>
    <p>
      Most templates now provide an option to override the default font size
      defined in the calendar data source settings. This option is useful
      because some templates might work better with a larger or smaller font
      size for events. It's a pain to modify the font size in each calendar data
      source before generating. The font size can now be overridden with
      the&nbsp;"Item font size" setting.
    </p>
    <p>
      <Image lazy={false} alt="" src={item_font_size} style={{ width: 478 }} />
    </p>
    <p>
      Here's an example of the default Month template when overriding the font
      size to be 16:
    </p>
    <p>
      <Image lazy={false} alt="" src={item_font_size_example} style={{ width: 1108 }} />
    </p>
    <h3>Use bold text for item times</h3>
    <p>
      Many templates now include an option to use bold text for item times. This
      helps provide some visual separation between items and makes it easier to
      see when items start and end.
    </p>
    <p>
      <Image lazy={false} alt="" src={use_bold_text} style={{ width: 473 }} />
    </p>
    <p>
      Here's an example of the default Calendar Columns template when using bold
      text for item times:
    </p>
    <p>
      <Image lazy={false} alt="" src={use_bold_text_example} />
    </p>
    <h3>All-day area text behavior</h3>
    <p>
      Templates that include an "all-day area", such as the Day template and
      Week Column template, now include an option to control the behavior of
      all-day item titles. Previously, item titles would only appear on one
      line, and the title text could be clipped if there wasn't enough space.
      Now, you can choose to wrap all-day item titles, shrink the titles to fix
      the available space on 1 line, or behave the same as the previous version.
    </p>
    <p>
      <Image lazy={false} alt="" src={all_day_area_text_behavior} style={{ width: 446 }} />
    </p>
    <p>
      Here's an example of the default Week Column template when the all-day
      text behavior is set to wrap text:
    </p>
    <p>
      <Image lazy={false} alt="" src={all_day_area_text_behavior_example} />
    </p>
    <h3>Show timed multi-day items in time slots</h3>
    <p>
      Templates that include an all-day area and timeslots, such as the Day and
      Week Column templates, now include an option to control where items appear
      when they have start/end times and span multiple days. Previously, those
      items would always appear in the all-day area, even though they aren't
      all-day events (they just span across days). Now, the "Show timed
      multi-day items in all-day area" option can be unchecked to result in
      those items appearing within the correct timeslots on each day.
    </p>
    <p>
      <Image
        alt=""
        src={show_times_multiday_items_in_all_day_area}
        style={{ width: 446 }}
      />
    </p>
    <p>
      Here's an example of how this looks when using the default 4 Day template:
    </p>
    <p>
      <Image
        alt=""
        src={show_times_multiday_items_in_all_day_area_example}
        style={{ width: 700 }}
      />
    </p>
    <h3>Show tasks that have no due date</h3>
    <p>
      The 1 Day/Page with Task List template now provides an option for showing
      or hiding tasks that don't have a due date. Previously, tasks without due
      dates would always appear in the output task list.
    </p>
    <p>
      <Image
        alt=""
        src={show_tasks_that_have_no_due_date}
        style={{ width: 449 }}
      />
    </p>
    <h3>Show blank line between items</h3>
    <p>
      The Agenda template has been improved to include an option for controlling
      whether a blank line appears between items. Previously, a blank line would
      always be inserted. This resulted in additional white space that might not
      be desired. It also complicated the ability to customize the template and
      its layout.&nbsp;
    </p>
    <p>
      <Image
        alt=""
        src={show_blank_line_between_items}
        style={{ width: 443 }}
      />
    </p>
    <p>Here's an example when the option to show blank lines is unchecked:</p>
    <p>
      <Image
        alt=""
        src={show_blank_line_between_items_example}
        style={{ width: 741 }}
      />
    </p>
    <h3>Numeric time format</h3>
    <p>
      Most templates now include a "Numeric" time format, in addition to the
      default, shortened, 12-hour, 14-hour, and military time formats provided
      previously. The numeric format shows fractional hours. For example, 10:45
      AM will be shown as 10.75 when using the Numeric time format.&nbsp;
    </p>
    <p>
      <Image lazy={false} alt="" src={numeric_time_format} style={{ width: 448 }} />
    </p>
    <h3>Item sort order</h3>
    <p>
      Some templates, such as the Month and Multiweek templates, now include an
      option sort items either by starting time or by associated calendar data
      source. In the previous version, items were always sorted by start time.
      When sorting by associated calendar, items will be grouped by calendar
      within each day, instead of appearing in sequential order. A similar
      option has also been added to the 1 Day/Page with Task List template,
      where the listed tasks can be grouped by calendar data source instead of
      when they're due.
    </p>
    <p>
      <Image lazy={false} alt="" src={item_sort_order} style={{ width: 449 }} />
    </p>
    <p>
      When sorting by calendar data source, the order is determined by the list
      of calendar data sources. Data sources can be moved up or down in the list
      to control the ordering of events and tasks in the output.
    </p>
    <p>
      <Image lazy={false} alt="" src={item_sort_order_calendars} style={{ width: 449 }} />
    </p>
    <p>
      Here's an example of how this looks when sorting items by calendar data
      source:
    </p>
    <p>
      <Image lazy={false} alt="" src={item_sort_order_example} style={{ width: 752 }} />
    </p>
    <h3>Show time on separate line</h3>
    <p>
      The Day Calendar Columns template has been improved with an option for
      showing item times and locations on separate lines.
    </p>
    <p>
      <Image
        alt=""
        src={show_time_and_locations_on_separate_lines}
        style={{ width: 447 }}
      />
    </p>
    <p>
      Here's an example of how this looks when both times and locations are
      configured to appear on separate lines:
    </p>
    <p>
      <Image
        alt=""
        src={show_time_and_locations_on_separate_lines_example}
        style={{ width: 995 }}
      />
    </p>
  </div>
);

const includeLegendInTemplates = (
  <div id="include-legend-in-templates">
    <h2>Include legend in templates</h2>
    <p>
      In the previous version, you could insert a color-coded legend for the
      calendar data sources, but this wasn't automated and would need to be done
      after generating a calendar. In PrintableCal 2019, support has been added
      for automatically generating a legend as part of the template. Most
      templates will now include a legend in their output. The legend can also
      show color-coding for associated categories, not just calendar data
      sources.
    </p>
    <p>
      <Image lazy={false} alt="" src={legend_example} />
    </p>
    <p>
      To include a legend when customize a template, name a cell range "Legend"
      where the legend should be placed. Set the value of the Legend cell to
      "Legend(Columns=X)", where X is a number between 1 and 10. This value
      controls how many columns can be shown in the legend before wrapping to a
      new row. You could set the number of columns based on how much space is
      available in the template and the length of your calendar or category
      names. Align the cell text based on how you want the output legend to be
      aligned. For example, if you want the legend aligned with the right edge
      of the cell, set the text alignment to right.
    </p>
    <p>
      <Image lazy={false} alt="" src={legend_customize} />
    </p>
  </div>
);

const insertMiniCalendar = (
  <div id="insert-mini-calendar">
    <h2>Insert mini-calendar</h2>
    <p>
      PrintableCal 2019 adds the ability to insert a mini calendar, either at
      the current edit location, or as part of a template that gets
      automatically generated.
    </p>
    <p>
      To insert a mini calendar at the current edit location in Excel, Word, or
      PowerPoint, click the "Mini Calendar" button on the PrintableCal tab of
      the ribbon. Select which month should appear first, the dimensions (in
      number of months as columns x rows), the font size of the dates in the
      mini calendar, and the mini calendar flow direction. After configuring the
      mini calendar, click the Insert button to insert at the current edit
      location.
    </p>
    <p>
      <Image lazy={false} alt="" src={insert_mini_calendar_before} />
    </p>
    <p>
      <Image lazy={false} alt="" src={insert_mini_calendar_after} />
    </p>
    <p>
      Many of the templates included with PrintableCal have been improved to
      automatically include a mini calendar. For example, when generating the
      Week Column template, you'll see a mini month calendar in the upper-right
      corner.
    </p>
    <p>
      <Image lazy={false} alt="" src={mini_calendar_example_template} />
    </p>
    <p>&nbsp;</p>
    <p>
      Similar to how Legends can be automatically inserted, you can also
      customize templates to include a mini calendar. Name the cell range where
      the mini calendar should appear "MiniCalendar", and set the cell value to
      "MiniCalendar(Columns=X,Rows=Y)", where X is the desired number of columns
      and Y is the desired number of rows. For example, to generate a mini
      calendar that contains a 3x4 month grid (12 months total), set the value
      to "MiniCalendar(Columns=3,Rows=4)". The mini calendar will be aligned
      within the named cell range based on the specified text alignment.
    </p>
    <p>
      <Image lazy={false} alt="" src={mini_calendar_customize_template} />
    </p>
  </div>
);

const importCreateAndColorCodeCategories = (
  <div id="categories">
    <h2>Import, create, and color-code categories</h2>
    <p>
      PrintableCal 2019 features many improvements related to categories.
      Categories can now be imported or created in the PrintableCal Options.
      When categories are defined, events and tasks will be color-coded in the
      output based on the associated category colors, rather than the calendar
      data source colors. Depending on the data source, this can also be
      overridden (more on this later).
    </p>
    <p>
      To get started with categories in PrintableCal, open the Options window by
      clicking the Options button on the PrintableCal tab. Categories are
      specified on the "Category Colors" tab of the Options window. If you use
      Exchange, Outlook, or VueMinder, you can import categories from those
      sources by clicking the "Import" button.
    </p>
    <p>
      <Image lazy={false} alt="" src={categories_import} style={{ width: 887 }} />
    </p>
    <p>
      <span>
        You can also define categories by clicking the "New Category" button.
        Each category can be configured with colors (background, text, and
        border), font (name, size, style, alignment), and border thickness.
      </span>
    </p>
    <p>
      <Image lazy={false} alt="" src={categories_defined} style={{ width: 887 }} />
    </p>
    <p>
      <span>
        When generating a calendar, any items associated with a defined category
        will use that category's appearance settings. Templates that include a
        legend will also show the category as part of the legend. Not all the
        defined categories will be in the legend - only categories associated
        with items that are actually shown in the output.
      </span>
    </p>
    <p>
      <Image lazy={false} alt="" src={categories_legend_in_template} />
    </p>
    <p>
      You can also manually insert a legend for selected categories. Click the
      "Legend" button on the PrintableCal tab and select "Categories" from the
      "Legend type" drop-down list. Use the check boxes to select which
      categories should be included.
    </p>
    <p>
      <Image lazy={false} alt="" src={categories_inserted} style={{ width: 859 }} />
    </p>
    <p>
      Depending on the data source of your events and tasks, there may be
      options to control whether the output is automatically colored based on
      associated category, or using a different criteria. For example, if you
      want to use category colors when importing from VueMinder, make sure to
      select this in the PrintableCal Options.
    </p>
    <p>
      <Image lazy={false} alt="" src={category_vueminder_options} style={{ width: 887 }} />
    </p>
  </div>
);

const filterNuCategoryOrItemType = (
  <div id="filters">
    <h2>Filter by category or item type</h2>
    <p>
      PrintableCal 2019 adds the ability to perform additional filtering on the
      items that appear in the output.{' '}
      <span>
        The filtering criteria is specified below the list of calendar data
        sources.
      </span>
    </p>
    <p>
      <Image lazy={false} alt="" src={filter_none} style={{ width: 445 }} />
    </p>
    <p>
      Items can be filtered if they are associated with a specified categories,
      if they are marked as "all-day", or if they have start/end times. To
      filter multiple categories, separate the category names with
      semicolons.&nbsp;
    </p>
    <p>
      <Image lazy={false} alt="" src={filter_applied} style={{ width: 445 }} />
    </p>
    <p>
      Any specified filter will be the default the next time a calendar is
      generated. If a filter is applied, a red border will appear around the
      filter box to remind you that some items might not appear in the output.
    </p>
  </div>
);

const defineCalendarDataSourcePresets = (
  <div>
    <div id="presets">
      <h2>Define calendar data source presets</h2>
      <p>
        PrintableCal 2019 adds the ability to define and re-use preset
        combinations of selected calendar data sources when generating templates
        or legends. In the previous version, PrintableCal would remember which
        calendar data sources were most recently selected, but if you wanted to
        use different combinations of calendar data sources when generating with
        different templates, you'd need to manually check or uncheck the
        calendar data sources each time, which could become tedious.
      </p>
    </div>
    <p>
      To save a preset, first select the calendar data sources you'd like to
      include in the preset by using the check boxes next to the calendar names.
      After selecting the desired calendars, enter a name for the preset and
      click the Save button.
    </p>
    <p>
      <Image lazy={false} alt="" src={calendar_preset_save} style={{ width: 449 }} />
    </p>
    <p>
      You can save any number of presets. To select a previously defined preset,
      type its name in the "Preset" field, or select from the drop-down list.
      The checked calendar data sources will update to match what was previously
      saved in the preset.
    </p>
    <p>
      <Image lazy={false} alt="" src={calendar_preset_select} style={{ width: 456 }} />
    </p>
    <p>
      You can also modify a previously defined preset by selecting the desired
      calendars and clicking the Save button. If you no longer need a preset, it
      can be removed by clicking the Delete button. This won't remove the
      calendar data sources associated with the preset.
    </p>
  </div>
);

const customizeItemTemplates = (
  <div id="item-template">
    <h2>Customize item templates</h2>
    <p>
      PrintableCal 2019 adds the ability to customize the item template that is
      used for events and tasks in most of the templates. There are 5 fields
      that can be configured in any order. You can pick and choose from these
      fields, or remove them from the template if there are things you never
      want to see in the output. In some cases, template settings will also
      control whether these fields are visible, such as the template setting for
      including locations. The field names are wrapped in [square brackets] in
      the item template. Additional static text can also be inserted into the
      template if desired.
    </p>
    <p>
      <Image lazy={false} alt="" src={item_template_default} style={{ width: 887 }} />
    </p>
    <ol>
      <li>
        <strong>[Time]:</strong> The item time. The format of the time, and
        whether the end time is also shown, is determine in the template
        settings when generating.
      </li>
      <li>
        <strong>[Calendar Prefix]:</strong> The prefix of the associated
        calendar data source. Calendar prefixes can be specified in the settings
        panel that appears when a calendar data source is selected.
      </li>
      <li>
        <strong>[Title]:</strong> The item title or subject.
      </li>
      <li>
        <strong>[Occurrence Number]: </strong>For items that follow a recurrence
        pattern, the sequential occurrence number of the item. For example, this
        could be a person's age for a yearly recurring birthday event. The first
        occurrence of a yearly event will have an occurrence number of 0.
        Monthly, weekly, and daily events will start with an occurrence number
        of 1.
      </li>
      <li>
        <strong>[Location]:</strong> The item's location.
      </li>
    </ol>
    <p>
      In addition to the ordering of fields, you can also specify additional
      text that should come before or after each value. For example, you might
      want the calendar prefix to be followed by a dash and the item location to
      be prefixed with a semicolon. A preview is shown to the right of each
      field to show how it will look. An example item is also shown at the
      bottom of the Item Template tab so you can see the overall effect of the
      specified template.
    </p>
    <p>
      The default template is "[Time][Calendar Prefix][Title][Location]". This
      default was overridden in the example shown below. Occurrence numbers will
      come first, then titles followed by times in parentheses. Locations will
      not be shown. You can restore the default template by clicking the
      "Restore Defaults" button.
    </p>
    <p>
      <Image lazy={false} alt="" src={item_template_custom} style={{ width: 887 }} />
    </p>
  </div>
);

const insertCalendarUsingAdditionalFormatsInWord = (
  <div id="word-formats">
    <h2>Insert calendar using additional formats in Word</h2>
    <p>
      When generating calendars in Word, PrintableCal 2019 provides 4 different
      format options - Excel Worksheet Object, Formatted Text (RTF), Picture,
      and HTML.
    </p>
    <p>
      <Image lazy={false} alt="" src={word_formats} style={{ width: 472 }} />
    </p>
    <p>
      Previously, there was only an option to insert as a picture or an Excel
      Worksheet Object. In both of those cases, each generated worksheet needs
      to fit on a single page in Word. Depending on the template, this is not
      always possible. For example, the Agenda template will generate to a
      single worksheet in Excel. Since the Agenda template can contain hundreds
      of days, the output in the previous version could get scaled so small in
      Word that it would be unreadable. This can be solved in PrintableCal 2019
      by using the HTML or RTF format.
    </p>
    <p>
      In the example below, the HTML format was used for the Agenda template. As
      you can see, the output seamlessly flows across multiple pages, rather
      than trying to fit into a single page.
    </p>
    <p>
      <Image lazy={false} alt="" src={word_html_format_example} />
    </p>
  </div>
);

const connectToMoreCalendarDataSourceType = (
  <div id="more-calendar-data-source-types">
    <h2>Connect to more calendar data source types</h2>
    <p>
      PrintableCal 2019 adds to ability to import from Todoist, Excel, and CSV
      files.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_todoist_menu} style={{ width: 445 }} />
      <Image lazy={false} alt="" src={add_csv_and_excel_menu} style={{ width: 445 }} />
    </p>
    <h3>Todoist</h3>
    <p>
      PrintableCal can connect to any number of Todoist accounts. When
      commanding a Todoist calendar data source to be added, a window will allow
      selection of a previously connected account, or a new connection can be
      added by entering an account name and API token. Instructions are provided
      at the bottom of the window.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_todoist_instructions} style={{ width: 736 }} />
    </p>
    <p>
      After connecting, a list of calendars will be shown. Use the check boxes
      to select which calendars should be added to PrintableCal.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_todoist_calendars} style={{ width: 737 }} />
    </p>
    <h3>Excel and CSV Files</h3>
    <p>
      PrintableCal can now import from Excel and CSV files. The files must be
      formatted with a header row, followed by any number of event or task rows.
      When adding an Excel or CSV file, PrintableCal will try to determine the
      mapping of columns found in the input file. You can override the column
      mapping as needed. Since PrintableCal only uses a subset of the possible
      data, some fields won't have any mapping to PrintableCal. The mapping for
      non-applicable fields can be set blank to be ignored, or set to the
      Description field to have their contents appended to the item descriptions
      that appear in some templates.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_csv_mapping} style={{ width: 709 }} />
    </p>
  </div>
);

const connectToAnyNumberOfGoogleAccounts = (
  <div id="google-accounts">
    <h2>Connect to any number of Google accounts</h2>
    <p>
      The previous version of PrintableCal was limited to connecting to a single
      Google account. In PrintableCal 2019, you can connect to any number of
      accounts. This is useful if you have multiple Google accounts and don't
      (or can't) share calendars between your accounts. Also, since task lists
      can't be shared between Google accounts, this feature makes it possible to
      merge all the tasks in all your accounts into the same output.
    </p>
    <p>
      When commanding the addition of a Google Calendar or Google Tasks data
      source, a window will appear where you can select a previously connected
      account, or add a connection to a new account. To add a connection, enter
      your account name and click the Next button.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_google_account} style={{ width: 737 }} />
    </p>
    <p>
      A web page will appear where you can confirm your account selection. After
      confirming, click the "Allow" button.
    </p>
    <p>
      <Image lazy={false} alt="" src={google_allow_access} style={{ width: 479 }} />
    </p>
    <p>
      After allowing access, the list of calendars in your account will appear.
      Use the check boxes to select which calendars should be added to
      PrintableCal.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_google_calendars} style={{ width: 737 }} />
    </p>
  </div>
);

const specifyVueMinderDataFileLocation = (
  <div id="vueminder">
    <h2>Specify VueMinder data file location</h2>
    <p>
      PrintableCal 2019 improves connecting to VueMinder, especially when using
      VueMinder USB. Previously, PrintableCal would default to using the data
      location of the locally installed VueMinder. If you also use the USB
      version of VueMinder, there was no way to connect PrintableCal to the USB
      version's data. Now you can specify the VueMinder data location and this
      location will be remembered the next time you command PrintableCal to add
      a calendar from VueMinder.
    </p>
    <p>
      <Image lazy={false} alt="" src={add_vueminder} style={{ width: 737 }} />
    </p>
  </div>
);

const manyOtherCorrectionsAndImprovements = (
  <div id="many-other-improvements">
    <h2>Many other improvements and corrections</h2>
    <p>Besides all the improvements noted above, there's a lot more...</p>
    <ul>
      <li>
        Added support for color-coding the item list in the Year template.
      </li>
      <li>
        Improved performance and reliability of adding Google Calendar data by
        updating to use the latest Google Calendar API
      </li>
      <li>
        Improved the Year and 6 Month templates' event list to merge multi-day
        events into single row, rather than repeating to multiple rows.
      </li>
      <li>
        Improved the Agenda template to support generating up to 1000 days.
      </li>
      <li>
        Improved the Week Column template restore the previously selected first
        day of the week as the default.
      </li>
      <li>
        Improved connecting to iCalendar URLs to support TLS1.2, which some URLs
        require.
      </li>
      <li>
        Corrected the Agenda template to not overwrite additional user-defined
        rows that are defined below the date header.
      </li>
      <li>
        Corrected to not show an error in the case where a template contains an
        additional worksheet which can have the same name as a dynamically
        generated worksheet.
      </li>
      <li>
        Corrected an error that can occur on some computers when attempting to
        import from VueMinder.
      </li>
      <li>
        Corrected event times to be converted to UTC if events in an ICS file
        reference TZID=UTC, even if there's no UTC time zone defined in the ICS
        file.
      </li>
      <li>
        Corrected a "invalid template" message that could sometimes appear after
        saving a custom template when another instance of Excel is running.
      </li>
      <li>
        Corrected the "Add Outlook.com Calendar Data Source" window to enable
        the OK button after entering the calendar URL.
      </li>
      <li>
        Corrected items in other months of the Month template to not be clipped
        when the option to clip those items is unchecked.
      </li>
      <li>
        Corrected the Week Grid template to properly calculate row heights when
        wrapping events that have locations.
      </li>
      <li>
        Corrected the Connect to Exchange window to clear any previously
        specified URL. In some cases, the URL field could contain previous text
        but be hidden, resulting in errors when attempting to connect.
      </li>
      <li>
        Corrected importing from any iCalendar-based data source. In some cases,
        event times were an hour off, especially around DST clock changes.
      </li>
      <li>
        Corrected to save calendar data sources and Google authentication tokens
        to the user's Local app data folder, not Roaming.
      </li>
      <li>
        Corrected the "High Contrast Week (Weekdays Only)" template to show the
        correct colors when generating in Word and inserting as a picture.
      </li>
      <li>
        Corrected Word 2010 to not crash when exiting or when switching between
        documents if the PrintableCal panel was previously opened.
      </li>
      <li>Many other corrections and improvements.</li>
    </ul>
  </div>
);

const content = (
  <div className="sectioned">
    {toc}
    {newUserInterface}
    {generateCalendarsInPowerPoint}
    {generateCalendarsUsingNewTemplates}
    {customizeOutputWithMoreSettings}
    {includeLegendInTemplates}
    {insertMiniCalendar}
    {importCreateAndColorCodeCategories}
    {filterNuCategoryOrItemType}
    {defineCalendarDataSourcePresets}
    {customizeItemTemplates}
    {insertCalendarUsingAdditionalFormatsInWord}
    {connectToMoreCalendarDataSourceType}
    {connectToAnyNumberOfGoogleAccounts}
    {specifyVueMinderDataFileLocation}
    {manyOtherCorrectionsAndImprovements}
  </div>
);

const Documentation = () => {
  return (
    <DocumentationSection
      url="https://www.printablecal.com/Whats-New-Version-2019"
      commentsId="whats_new_version_2019"
      title="What's New in PrintableCal 2019"
      description="PrintableCal 2019 features a new user interface, PowerPoint add-in, new calendar templates and settings, and many other improvements."
      keywords="PrintableCal, release notes, version history"
      content={content}
    />
  );
};

export default Documentation;
